var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:`extended-data-tile-organism is--variant-${_vm.variant}`},[(_vm.description)?_c('div',{staticClass:"data-tile-header"},[_c('EsyH2',[_c('strong',[_vm._v(_vm._s(_vm.headline))])]),_vm._v(" "),_c('EsyParagraph',[_vm._v(" "+_vm._s(_vm.description)+" ")])],1):_c('EsyH3',[_c('strong',[_vm._v(_vm._s(_vm.headline))])]),_vm._v(" "),(_vm.showBatchLabel)?_c('EsyParagraph',{staticClass:"batch-label",attrs:{"variant":"small"}},[_vm._v("\n    "+_vm._s(_vm.returnRelatedBatchLabel(_vm.headline))+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"single-data-container"},[_c('EsyParagraph',{staticClass:"price-label",attrs:{"variant":"small"}},[_vm._v(" Heute ")]),_vm._v(" "),_c('div',{staticClass:"price"},[_c('EsyParagraph',[_vm._v(_vm._s(_vm.returnPriceFormatDE(_vm.data.currentPrice.value)))]),_vm._v(" "),_c('Icon',{class:`is--trend-${_vm.returnTrendDirectionString(
          _vm.data.relativeChange,
        )}`,attrs:{"icon":`arrow-circle-${_vm.returnTrendDirectionString(
          _vm.data.relativeChange,
        )}-solid.svg`}})],1),_vm._v(" "),_c('div',{staticClass:"change-time-value-container"},[_c('EsyParagraph',{staticClass:"update-time",attrs:{"variant":"small"}},[_c('Icon',{attrs:{"icon":"history-solid.svg"}}),_vm._v(" "+_vm._s(_vm.daydate.time)+" Uhr\n      ")],1),_vm._v(" "),_c('div',{class:`relative-change-container is--trend-${_vm.returnTrendDirectionString(
          _vm.data.relativeChange,
        )}`},[_c('EsyParagraph',{staticClass:"relative-change--value"},[_vm._v("\n          "+_vm._s(_vm.getAbsoluteChange(_vm.data.currentPrice.value, _vm.data.lastPrice.value))+"\n        ")])],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"last-change-container"},[_c('div',{staticClass:"row"},[_c('EsyParagraph',[_c('strong',[_vm._v("Gestern")])]),_vm._v(" "),_c('EsyParagraph',{staticClass:"last-change--value"},[_c('strong',[_vm._v(_vm._s(_vm.returnPriceFormatDE(_vm.data.lastPrice.value)))])])],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('EsyParagraph',{attrs:{"variant":"small"}},[_vm._v(" Veränderung zum Vortag ")]),_vm._v(" "),_c('EsyParagraph',{class:`is--trend-${_vm.returnTrendDirectionString(
          _vm.data.relativeChange,
        )}`,attrs:{"variant":"small"}},[_vm._v("\n        "+_vm._s(_vm.getFormattedPercentage(
            _vm.data.currentPrice.value,
            _vm.data.lastPrice.value,
          ))+"\n      ")])],1)]),_vm._v(" "),(_vm.disclaimer)?_c('div',{staticClass:"disclaimer-container"},[_c('EsyParagraph',{attrs:{"variant":"small"}},[_vm._v(" "+_vm._s(_vm.disclaimer)+" ")])],1):_vm._e(),_vm._v(" "),(_vm.data.currentPrice.value == 0)?_c('LoadingSpinner',{staticClass:"loading-spinner",attrs:{"timelimitMiliseconds":10000,"loadingFailedMessage":"Beim laden der Daten ist etwas schief gelaufen, bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal."}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }