var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"line-chart-organism"},[_c('div',{staticClass:"canvas-container"},[_vm._m(0),_vm._v(" "),_c('EsyParagraph',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.mainData.length > 0 || _vm.compareData.length > 0 || _vm.labels.length > 0
      ),expression:"\n        mainData.length > 0 || compareData.length > 0 || labels.length > 0\n      "}]},[_vm._v("\n      "+_vm._s(_vm.yAchsesLabel)+"\n    ")]),_vm._v(" "),_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.mainData.length > 0 || _vm.compareData.length > 0 || _vm.labels.length > 0
      ),expression:"\n        mainData.length > 0 || compareData.length > 0 || labels.length > 0\n      "}],ref:"chart",staticClass:"line-chart-canvas",attrs:{"id":_vm.canvasID,"width":"1920","height":"1080"}}),_vm._v(" "),(
        _vm.mainData.length === 0 ||
        _vm.compareData.length === 0 ||
        _vm.labels.length === 0
      )?_c('LoadingSpinner',{staticClass:"loading-spinner",attrs:{"timelimitMiliseconds":10000,"loadingFailedMessage":"Beim laden der Daten ist etwas schief gelaufen, bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal."}}):_vm._e()],1),_vm._v(" "),(_vm.chartDescription)?_c('EsyFigcaption',[_vm._v(_vm._s(_vm.chartDescription))]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"my-tooltip"}},[_c('div',{attrs:{"id":"tooltip-span"}}),_vm._v(" "),_c('span',{staticClass:"tooltip-di"},[_c('span',{staticClass:"tooltip-c",attrs:{"id":"tooltip-c0"}}),_vm._v(" "),_c('span',{staticClass:"tooltipData",attrs:{"id":"tooltip-p1"}})]),_vm._v(" "),_c('span',{staticClass:"tooltip-di"},[_c('span',{staticClass:"tooltip-c",attrs:{"id":"tooltip-c1"}}),_vm._v(" "),_c('span',{staticClass:"tooltipData",attrs:{"id":"tooltip-p2"}})])])
}]

export { render, staticRenderFns }