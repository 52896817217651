
// Libraries
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Component, Prop, Vue } from "nuxt-property-decorator";
dayjs.extend(utc);

// Components
import DataTileItem from "~/components/molecules/display/DataTileItem.vue";
import LoadingSpinner from "~/components/molecules/display/LoadingSpinner.vue";
import { getAbsoluteChange } from "~/mixins/oilPricemixin";

// Mixins
import {
  getFormattedPercentage,
  returnPriceFormatDE,
} from "~/mixins/formatting";

@Component({
  components: {
    DataTileItem,
    LoadingSpinner,
  },
  methods: {
    getFormattedPercentage,
    returnPriceFormatDE,
    getAbsoluteChange,
  },
})
export default class ExtendedDataTileOrganism extends Vue {
  @Prop() headline: string;
  @Prop() description: string;
  @Prop() disclaimer: string;
  @Prop({ default: "default" }) variant: string;
  @Prop({ default: "default" }) priceLabel: string;
  @Prop({ default: false }) showBatchLabel: boolean;
  @Prop({
    type: Object,
    default() {
      return {
        name: "string",
        currentPrice: {
          value: 0,
          changedAtUnix: 0,
        },
        lastPrice: {
          value: "number",
          changedAtUnix: 0,
        },
        relativeChange: 0,
      };
    },
  })
  data: {
    name: string;
    currentPrice: { value: number; changedAtUnix: number };
    lastPrice: { value: number; changedAtUnix: number };
    relativeChange: number;
  };

  date = new Date();
  daydate = {
    today: dayjs().format("DD.MM.YYYY"),
    time: dayjs().format("HH:mm"),
  };

  returnTrendDirectionString(trendValue: number) {
    const NumberTrendValue = Number(trendValue);
    if (NumberTrendValue === 0) return "right";
    if (NumberTrendValue < 0) return "sinking";
    if (NumberTrendValue > 0) return "rising";
    return "unknown";
  }

  returnRelatedBatchLabel(assetName: string) {
    if (assetName === "Brent") return "Eur/Barrel";
    if (assetName === "Gasöl") return "Eur/Tonne";
    return "";
  }
}
