
// Libraries
import { Component, Prop, Watch, Vue } from "nuxt-property-decorator";
import { CrosshairPlugin, Interpolate } from "chartjs-plugin-crosshair";
import { Chart, registerables, Tooltip } from "chart.js";
import { getFormattedLabel } from "@/operations/screensizes";
import gradient from "chartjs-plugin-gradient";
import dayjs from "dayjs";
// Components
import LoadingSpinner from "~/components/molecules/display/LoadingSpinner.vue";

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class LineChartOrganism extends Vue {
  @Prop({ required: true }) canvasID: string;
  @Prop() chartDescription: string;
  @Prop() yAchsesLabel: string;
  @Prop() chartDataLabels: Array<string | number>;
  @Prop({ default: "Datensatz 1" }) mainChartDataLabel: string;
  @Prop() mainChartDataSeries: Array<string>;
  @Prop({ default: "Datensatz 2" }) comparsionChartDataLabel: string;
  @Prop() comparsionChartDataSeries: Array<string>;

  OilChartObject;

  get jahresvergleichTooltipHtmlElements() {
    return [
      document.getElementById("tooltip-p1"),
      document.getElementById("tooltip-p2"),
    ];
  }
  get jahresvergleichTooltipColor() {
    return [
      document.getElementById("tooltip-c0"),
      document.getElementById("tooltip-c1"),
    ];
  }

  getTooltip = (chart) => {
    let tooltip = document.getElementById("my-tooltip");
    let tooltip_span = document.getElementById("tooltip-span");
    let oilPrices = document.getElementById("jahresvergleich");
    let xAlignLeft = window.innerWidth >= 680 ? 45 : 10;

    tooltip_span.innerHTML = chart.tooltip.title;
    if (chart.tooltip.x != undefined) {
      this.jahresvergleichTooltipHtmlElements.forEach((ele, i) => {
        ele.innerHTML =
          chart.tooltip.dataPoints[i] === undefined
            ? null
            : `${chart.tooltip.body[i].lines[0]}`;
      });
    }

    tooltip.style.top = chart.tooltip.y + 40 + "px";

    tooltip.style.left =
      chart.tooltip.x +
      ((chart.tooltip.xAlign === "left" && chart.tooltip.yAlign === "bottom") ||
      (chart.tooltip.xAlign === "left" && chart.tooltip.yAlign === "top")
        ? xAlignLeft + 20
        : chart.tooltip.xAlign === "left"
        ? xAlignLeft
        : chart.tooltip.xAlign === "center"
        ? -95
        : -40) +
      "px";

    return tooltip;
  };

  // 1 trigger
  externalTooltipHandler = (context) => {
    const { chart, tooltip } = context;
    if (context.tooltip.x != undefined) {
      this.jahresvergleichTooltipColor.forEach((ele, i) => {
        ele.style.backgroundColor =
          context.tooltip.labelColors[i] === undefined
            ? "transparent"
            : context.tooltip.labelColors[i].borderColor;
      });
    }

    const tooltipEL = this.getTooltip(chart);

    if (tooltip.opacity === 0 || tooltip.body[0].lines[0] === undefined) {
      tooltipEL.style.opacity = "0";
      tooltipEL.style.display = "none";
    } else {
      tooltipEL.style.opacity = "1";
      tooltipEL.style.display = "block";
    }
  };

  get OilChart() {
    return {
      type: "line",
      data: {
        labels: this.chartDataLabels,
        datasets: [
          {
            label: this.mainChartDataLabel,
            data: this.mainChartDataSeries,
            borderColor: "	rgba(253, 200, 53, 1)",
            backgroundColor: "	rgba(253, 200, 53, 0)",
            borderWidth: 2.5,
            pointBorderColor: "	rgba(253, 200, 53, 1)",
            pointBackgroundColor: "	rgba(253, 200, 53, 1)",
            pointRadius: 0,
            pointHoverBorderColor: "#fff",
            pointHoverBorderWidth: 3,
            pointHoverRadius: 6,
          },
          {
            label: this.comparsionChartDataLabel,
            data: this.comparsionChartDataSeries,
            borderColor: "rgba(0, 0, 0, 1)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderWidth: 2.5,
            pointBorderColor: "rgba(0, 0, 0, 1)",
            pointBackgroundColor: "rgba(0, 0, 0, 1)",
            pointRadius: 0,
            pointHoverBorderColor: "#fff",
            pointHoverBorderWidth: 3,
            pointHoverRadius: 6,
            fill: true,
            gradient: {
              backgroundColor: {
                axis: "y",
                colors: {
                  0: "rgba(142, 142,142, 0.0)",
                  100: "rgba(142, 142,142, 0.06)",
                },
              },
            },
          },
        ],
      },
      options: {
        aspectRatio: (context) => (window.innerWidth < 700 ? 1 | 0.2 : 0.5 | 2),
        responsive: true,
        lineTension: 1,
        spanGaps: true,
        elements: {
          line: {
            cubicInterpolationMode: "monotone",
          },
        },
        title: {
          display: false,
          text: "Heizölpreis-Entwicklung in Deutschland",
          fontSize: 22,
          position: "bottom" as "bottom",
        },
        legend: {
          display: true,
          position: "bottom" as "bottom",
          align: "center" as "center",
          labels: {
            fontColor: "rgba(0,0,0)",
            usePointStyle: true,
            boxWidth: 5,
            padding: 20,
          },
        },
        hover: {
          mode: "index",
          intersect: false,
        },
        scales: {
          y: {
            position: "right",
            ticks: {
              beginAtZero: false,
              maxTicksLimit: 6,
              stepSize: 5,
              fontSize: 12,
              color: "#7d7d7d",
              callback(value, index, values) {
                return `${value} €`;
              },
            },
            scaleLabel: {
              display: false,
              labelString: "€ / 100 L",
              fontColor: "#000000",
              fontSize: 12,
            },
            grid: {
              color: "rgba( 211, 211, 219, 0.4 )",
              drawBorder: false,
              drawOnChartArea: true,
              drawTicks: true,
              tickMarkLength: 10,
            },
          },
          x: {
            type: "category",
            distribution: "linear" as "linear",
            ticks: {
              autoSkip: true,
              maxTicksLimit: () => (window.innerWidth < 620 ? 3 : 6),
              maxRotation: 0,
              fontSize: 12,
              color: "#7d7d7d",
              align: "center",
              callback: (context) => {
                const splittedDate = this.chartDataLabels[context]
                  .toString()
                  .split(".");
                if (window.innerWidth < 620) {
                  return dayjs(
                    `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`,
                  ).format("MMM");
                } else {
                  return this.chartDataLabels[context];
                }
              },
            },
            scaleLabel: {
              display: false,
              labelString: "",
              fontColor: "#000000",
              fontSize: 12,
            },
            grid: {
              color: "rgba( 211, 211, 219, 0.4 )",
              zeroLineColor: "#cbd0d2",
              zeroLineBorderDash: [3, 9],
              drawBorder: true,
              drawOnChartArea: true,
              drawTicks: true,
              tickMarkLength: 10,
            },
          },
        },

        plugins: {
          tooltip: {
            enabled: false,
            external: this.externalTooltipHandler,
            position: "custom",
            mode: "index",
            intersect: false,
            displayColors: true,
            xPadding: 20,
            yPadding: 20,
            caretPadding: 6,
            cornerRadius: 5,
            borderWidth: 1,
            bodySpacing: 5,
            borderColor: "#000",
            callbacks: {
              label: function (tooltipItem, data) {
                let label = `${tooltipItem.formattedValue}`;
                let FormattedLabel = getFormattedLabel(
                  tooltipItem.dataset.label,
                );
                return [`${FormattedLabel}: ${label} €`];
              },
            },
          },
          legend: {
            display: true,
            position: "bottom" as "bottom",
            align: "center" as "center",
            labels: {
              fontColor: "rgba(0,0,0)",
              usePointStyle: true,
              boxWidth: 20,
              padding: 20,
              pointStyle: "line",
              pointStyleWidth: 15,
            },
          },
          crosshair: {
            sync: {
              enabled: false,
            },
            zoom: {
              enabled: false,
            },
            line: {
              color: "hsla( 10, 1%, 1%, 1 )",
              width: 1,
              dashPattern: [10, 3],
            },
          },
        },
      },
    };
  }

  mounted() {
    Chart.register(...registerables);
    Chart.register(gradient);
    Chart.register(CrosshairPlugin);
    this.createChart(this.canvasID, this.OilChart);
    //@ts-ignore
    Tooltip.positioners.custom = function (elements, eventPosition) {
      const tooltip = this;
      return {
        x: eventPosition.x,
        y: eventPosition.y,
      };
    };
  }

  get labels() {
    return this.chartDataLabels;
  }

  get mainData() {
    return this.mainChartDataSeries;
  }

  get compareData() {
    return this.comparsionChartDataSeries;
  }

  @Watch("chartDataLabels")
  onChartDataLabels() {
    this.createChart(this.canvasID, this.OilChart);
  }

  @Watch("mainChartDataSeries")
  onMainChartDataSeries() {
    this.createChart(this.canvasID, this.OilChart);
  }

  @Watch("comparsionChartDataSeries")
  onComparsionChartDataSeries() {
    this.createChart(this.canvasID, this.OilChart);
  }

  createChart(chartId: any, chartData: any) {
    if (this.OilChartObject) {
      this.OilChartObject.destroy();
    }
    const ctx = document.getElementById(chartId) as HTMLCanvasElement;
    this.OilChartObject = new Chart(ctx, {
      type: chartData.type,
      data: chartData.data,
      options: chartData.options,
    });
  }
}
